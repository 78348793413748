<template>
  <div id="touscommerces">
    <h2>{{ $t('shops.title', { city: partner.name }) }}</h2>
    <a name="shops"></a>
    <carousel-3d
      v-if="shops.length"
      :clickable="false"
      :controls-visible="true"
      :width="300"
      :height="600"
      :display="5"
      :space="250"
      :perspective="0"
      :inverse-scaling="50"
    >
      <template v-for="(shop, i) in shops">
        <slide :key="shop.id" :index="i">
          <TousCommercesItem
            :shop-id="shop.id"
            :shop-slug="shop.slug"
            :image="shop.imageUrl"
            :name="shop.name"
            :description="shop.getDescriptionShort($l)"
            :favourite.sync="shop.favourite"
          />
        </slide>
      </template>
    </carousel-3d>
    <div class="d-lg-flex align-items-center justify-content-center">
      <div class="pt-md-3 mt-5">
        <router-link
          class="voir-tous"
          :to="{ name: 'shops' }"
        >
          {{ $t('shops.all') }}
        </router-link>
      </div>
      <div v-if="connected" class="pt-md-3 mt-5">
        <router-link
          class="voir-favoris"
          :to="{ name: 'favourite' }"
        >
          <img src="@/assets/favoris-icon-white-full.svg" />{{ $t('shops.favourite') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TousCommercesItem from '@/components/Sliders/TousCommercesItem.vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  name: 'TousCommerces',
  components: {
    Carousel3d,
    Slide,
    TousCommercesItem
  },
  data: () => ({
    loading: false,
    favorisActive: false
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    shops() {
      const shops = this.$store.getters['partner/shops']
      const odd = shops.filter((_, i) => i === 0 || i % 2 === 1)
      const even = shops.filter((_, i) => i !== 0 && i % 2 === 0)
      return odd.concat(even.reverse())
    },
    connected() {
      return this.$store.getters['auth/connected']
    }
  },
  mounted() {
    this.getShops()
  },
  methods: {
    getShops() {
      this.loading = true
      return this.$store.dispatch('partner/shops', { id: this.partner.id })
        .then(() => this.$emit('loaded', this.shops))
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style*/
.carousel-3d-slider {
  perspective: 2500px !important;
}
.carousel-3d-slide {
  border: none !important;
  background: transparent !important;
  overflow: visible !important;
}

#touscommerces .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

/* Overwritten Carousel 3d style*/
::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-white.svg") no-repeat;
  left: 10px;
  @include media-breakpoint-up(md) {
    left: calc(50vw - 280px) !important;
  }
  @include media-breakpoint-up(lg) {
    left: calc(50vw - 620px) !important;
  }
}

::v-deep .next {
  background: url("~@/assets/slider-next-white.svg") no-repeat;
  background-position-x: right;
  right: 10px !important;
  @include media-breakpoint-up(md) {
    right: calc(50vw - 280px) !important;
  }
  @include media-breakpoint-up(lg) {
    right: calc(50vw - 620px) !important;
  }
}
#touscommerces {
  position: relative;
  background-color: var(--red-color);
  padding-bottom: 75px;
  @include media-breakpoint-up(md) {
    padding: 40px 0px 100px 0px;
  }
}
h2 {
  font-weight: 900;
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
  color: white;
  padding: 0 20px;
  margin-top: 50px;
  display: inline-block;
  @include media-breakpoint-up(md) {
    font-size: 35px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}
.commercants-list {
  padding: 0 70px;
  display: flex;
  position: relative;
  z-index: 2;
  max-width: 1300px;
  margin: auto;
  justify-content: space-between;
  padding: 0;
}

.voir-tous, .voir-favoris {
  background-color: var(--green-color);
  border: none;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 20px;
  margin: 10px;
  border-radius: 15px;
  font-family: inherit;
  box-shadow: 0px 2px 5px #0000005e;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  &:hover {
    color: white;
    filter: brightness(95%);
  }
}

.voir-favoris {
  background-color: var(--yellow-color);
  img {
    width: 35px;
    padding-right: 5px;

    @include media-breakpoint-up(md) {
      width: 40px;
      padding-right: 10px;
    }
  }
}
</style>
