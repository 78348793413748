<template>
  <div>
    <Slider :partner-id="partnerId" />
    <TousCommerces @loaded="onShopsLoaded" />
    <EnvieSlider v-if="!hide.enviede" :partner-id="partnerId" @loaded="d => onLoaded('enviede', d)" />
    <div v-if="!hide.categories" class="pt-lg-5">
      <Categories :partner-id="partnerId" :title="$t('city.categories')" @loaded="d => onLoaded('categories', d)" />
    </div>
    <div class="d-lg-flex align-items-center justify-content-center pt-3 pb-5 mb-5">
      <div class="mt-5">
        <router-link class="cta-shop" :to="{ name: 'products', params: { partnerId } }">
          {{ $t('city.searchProducts') }}
        </router-link>
      </div>
      <div v-if="connected" class="mt-5">
        <router-link class="cta-favoris" :to="{ name: 'favourite', params: { partnerId } }">
          <img src="@/assets/favoris-icon-white-full.svg" />{{ $t('city.favourite') }}
        </router-link>
      </div>
    </div>
    <div v-if="!hide.selection" class="pt-lg-5 pb-lg-5">
      <SelectionSlider :partner-id="partnerId" @loaded="d => onLoaded('selection', d)" />
    </div>
    <div class="pt-lg-2 pb-5 mb-md-5">
      <Avantages />
    </div>
  </div>
</template>

<script>
import Slider from '@/components/Sliders/Slider.vue'
import Avantages from '@/components/Avantages.vue'
import TousCommerces from '@/components/Sliders/TousCommerces.vue'
import Categories from '@/components/Categories/Categories.vue'
import EnvieSlider from '@/components/Sliders/EnvieSlider.vue'
import SelectionSlider from '@/components/Sliders/SelectionSlider.vue'

export default {
  name: 'ViewHomeVilleIdentifie',
  components: {
    Slider,
    Avantages,
    TousCommerces,
    Categories,
    EnvieSlider,
    SelectionSlider
  },
  props: {
    partnerId: { type: String, required: true }
  },
  data: () => ({
    hide: {
      categories: false,
      enviede: false,
      selection: false
    }
  }),
  computed: {
    connected() {
      return this.$store.getters['auth/connected']
    }
  },
  methods: {
    onLoaded(type, arry) {
      this.hide[type] = !arry.length
    },

    onShopsLoaded(shops) {
      if (shops.length === 1) {
        // redirect on shop page
        return this.$router.push({ name: 'shop', params: { shopSlug: shops[0].slug } })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss">
@import '../assets/styles/youpaq.scss';

/* component Slider */
#slider {
  background: white !important;
}
#slider .slide-item .slide-icon .acheter-local {
  background: url('~@/assets/youpaq-label-new-green.png') no-repeat !important;
  background-size: 53% !important;
  width: 180px;
  height: 180px;

  @include media-breakpoint-up(md) {
      background-size: 80% !important;
  }

  @include media-breakpoint-up(lg) {
      background-size: contain;
      width: 300px;
      height: 300px;
  }
}
#slider .slide-content {
  background-color: var(--yellow-color) !important;
}
#slider .slide-content-text {
  color: white !important;
}

#slider .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 0px;
  @include media-breakpoint-up(md) {
    bottom: 240px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 325px;
  }
}
#slider .agile__actions {
  position: relative;
}
#slider .agile__nav-button--prev {
  display: none;
  left: calc(50vw - 150px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw - 385px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw - 700px);
  }
}
#slider .agile__actions .prev {
  background: url("~@/assets/slider-prev-yellow.svg") no-repeat;
}
#slider .agile__nav-button--next {
  display: none;
  left: calc(50vw + 90px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw + 305px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw + 570px);
  }
}
#slider .agile__actions .next {
  background: url("~@/assets/slider-next-yellow.svg") no-repeat;
}

#slider .agile__dots {
  position: absolute;
  left: calc(50vw - 53px);
  bottom: 0px;
}
#slider .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#slider .agile__dot--current button{
  background: url("~@/assets/slider-dot-current.svg") no-repeat !important;
}

/* Tous commerces */
#touscommerces .carousel-3d-slider {
  display: flex !important;
  justify-content: center !important;
}
#touscommerces .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

/* cta shop */
.cta-shop, .cta-favoris {
  background-color: var(--green-color);
  border: none;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 20px;
  margin: 10px;
  border-radius: 15px;
  font-family: inherit;
  box-shadow: 0px 2px 5px #0000005e;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  &:hover {
    color: white;
    filter: brightness(95%);
  }
}

.cta-favoris {
  background-color: var(--yellow-color);
  img {
    width: 35px;
    padding-right: 5px;

    @include media-breakpoint-up(md) {
      width: 40px;
      padding-right: 10px;
    }
  }
}

</style>
