<template>
  <div class="commercants-list-item">
    <router-link :to="{ name: 'shop', params: { shopSlug } }">
      <img :src="$resize(image, { w: 300, h: 200, f: 'cover' })">
    </router-link>
    <router-link :to="{ name: 'shop', params: { shopSlug } }">
      <h3>{{ name }}</h3>
    </router-link>
    <p><span v-marked="description"></span></p>
    <div
      class="ajouter-favoris"
      :class="{ activeFavoris: favorisActive }"
    >
      <button @click="toggleFavourite">
        <template v-if="!favorisActive">
          <img src="@/assets/favoris-icon-white-empty.svg" />
          <span>{{ $t('shop.addFavourite') }}</span>
        </template>
        <template v-else>
          <img src="@/assets/favoris-icon-white-full.svg" />
          <span>{{ $t('shop.removeFavourite') }}</span>
        </template>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TousCommercesItem',
  props: {
    shopId: {
      type: String,
      required: true
    },
    shopSlug: {
      type: String,
      required: true
    },
    image: {
      type: String,
      default: 'touscommerces-boulangerie.png'
    },
    name: {
      type: String,
      default: 'Commercant name'
    },
    description: {
      type: String,
      default: 'Commercant description'
    },
    favourite: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    favorisActive: {
      get() {
        return this.favourite
      },
      set(v) {
        this.$emit('update:favourite', v)
      }
    }
  },
  methods: {
    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/shopFavouriteToggle', { shopId: this.shopId })
        .then(active => {
          this.favorisActive = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.commercants-list-item {
  background-color: var(--gray-color);
  color: white;
  box-shadow: 1px 1px 8px 2px black;
  min-height: 600px;
  position: relative;

  a {
    color: white;
    text-decoration: none;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  h3 {
    padding: 0 25px;
    text-align: left;
    font-weight: 900;
    font-size: 18px;
    text-transform: uppercase;
    margin: 30px 0 5px 0;
  }
  p {
    padding: 0 25px;
    text-align: left;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5rem;
    padding-bottom: 20px;
  }
  button {
    padding-left: 25px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;

    img {
      width: 50px;
      @include media-breakpoint-up(md) {
        width: 30px;
      }
    }
    span {
      color: white;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      padding-left: 15px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }
    }
  }
}
.ajouter-favoris {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding-right: 25px;
}
.ajouter-favoris:not(.activeFavoris) {
  button.favoris-full {
    display: none;
  }
  button.favoris-empty {
    display: flex;
    align-items: center;
  }
}
.ajouter-favoris.activeFavoris {
  button.favoris-full {
    display: flex;
    align-items: center;
  }
  button.favoris-empty {
    display: none;
  }
}
</style>
